const Boxes = {
  box1: function () {
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 10, 10, -10],keepaspectratio: true, axis:true, ticks:{visible:false}, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
    brd1.options.layer['image'] =14;
	brd1.create('text', [-8, 9., '<b> Dot Product of Two Vectors <b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
	brd1.create('text', [-7, -2.5, '<b> Direct Method <b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
	brd1.create('text', [2, -2.5, '<b> Geometric Method <b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
	var PtO =brd1.create('point', [0, 0],{name:'<b>O</b>', face:'circle',size:2, strokeColor:'black', fillColor:'yellow', fixed:true, label:{CssStyle:'fontFamily:Oswald', offset:[0,20], fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
	var PtA =brd1.create('point', [-4, 4],{name:'<b>A</b>', fixed:false, snapToGrid:true, face:'circle',size:2, strokeColor:'black', fillColor:'yellow', label:{offset:[0,20], CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
	brd1.create('arrow', [PtO, PtA], {visible:true, strokeColor:'blue', strokeWidth:3});
	var ProjAx = brd1.create('point', [function(){return PtA.X()}, 0],{name: function(){return ''+PtA.X();}, snapToGrid:true, face:'circle',size:2, strokeColor:'black', fillColor:'yellow', fixed:false, label:{offset:[0,-20], CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
	var ProjAy = brd1.create('point', [0, function(){return PtA.Y()}],{name: function(){return ''+PtA.Y();}, snapToGrid:true, face:'circle',size:2, strokeColor:'black', fillColor:'yellow', fixed:false, label:{offset:[20,0], CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
	brd1.create('segment', [PtA, ProjAx],{dash:1});
	brd1.create('segment', [PtA, ProjAy],{dash:1});
	brd1.create('text', [9, 0.35, '<i>x&#770;</i>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
	brd1.create('text', [0.25, 9,  '<i>y&#770;</i>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
	//var moveA= brd1.create('point', [0,0],{name:'', face:'circle',size:2, strokeColor:'black', fillColor:'yellow'});
	//var moveB= brd1.create('point', [0,0],{name:'', face:'circle',size:2, strokeColor:'black', fillColor:'yellow'});
	////////////////////////////////////////////////////////////////////////////////
	var PtB =brd1.create('point', [3, 2],{name:'<b>B</b>',snapToGrid:true, face:'circle',size:2, strokeColor:'black', fillColor:'yellow', label:{offset:[0,20],CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
	var ProjBx = brd1.create('point', [function(){return PtB.X()}, 0],{name: function(){return ''+PtB.X();}, snapToGrid:true, face:'circle',size:2, strokeColor:'black', fillColor:'yellow', fixed:false, label:{offset:[0,-20], CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
	var ProjBy = brd1.create('point', [0, function(){return PtB.Y()}],{name: function(){return ''+PtB.Y();}, snapToGrid:true, face:'circle',size:2, strokeColor:'black', fillColor:'yellow', fixed:false, label:{offset:[20,0], CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
	brd1.create('segment', [PtB, ProjBx],{dash:1, strokeColor:'red'});
	brd1.create('segment', [PtB, ProjBy],{dash:1, strokeColor:'red'});
	////////////////////////////////////////////////////////////////////////
	brd1.create('arrow', [PtO, PtB], {visible:true, strokeColor:'red', strokeWidth:3});
	var a=brd1.create('angle', [PtB, PtO, PtA],{name:function(){return '&alpha; ='+ (JXG.Math.Geometry.rad(PtB, PtO, PtA)*180/Math.PI).toFixed(2)+'^o'},visible:true, radius:1,label:{CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
    brd1.create('text', [-7, -4, function(){return 'OA = ('+ (PtB.X()).toFixed(2)+') <i>x&#770;</i> + (' + (PtB.Y()).toFixed(2) +') <i>y&#770;</i>'}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
	//var plus=brd1.create('image', ['addition.svg', [-5, -5.5],[0.8, 0.8]], {opacity:1, fixed:true});
	brd1.create('text', [-7, -6, function(){return 'OB = ('+ (-PtO.X()+PtA.X()).toFixed(2) + ') <i>x&#770;</i> + (' + (-PtO.Y()+PtA.Y()).toFixed(2) +') <i>y&#770;</i>'}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
	brd1.create('text', [-8, -8, function(){return 'OA&middot;OB = ('+ (PtA.X()*PtB.X()).toFixed(2) + ') + (' + (PtA.Y()*PtB.Y()).toFixed(2)+') = '+ (PtA.X()*PtB.X()+PtA.Y()*PtB.Y()).toFixed(2)}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
	//
	 brd1.create('text', [2, -4, function(){return '||OA|| = '+ Math.sqrt(PtA.X()*PtA.X()+PtA.Y()*PtA.Y()).toFixed(2)}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
	 brd1.create('text', [2, -6, function(){return '||OB|| = '+ Math.sqrt(PtB.X()*PtB.X()+PtB.Y()*PtB.Y()).toFixed(2)}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
	  brd1.create('text', [2, -8, function(){return 'OA&middot;OB = ||OA|| ||OB|| cos(&alpha;) = '+ (PtB.X()*PtA.X()+PtB.Y()*PtA.Y()).toFixed(2)}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}});
              }
            }
export default Boxes;